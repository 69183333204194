/* ===================================================================
 * fonts.scss
 *
 * ------------------------------------------------------------------- */


/*
 * lora
================================================================================ */
@font-face {
    font-family: "lora-regular";
    src: url("../fonts/lora/lora-regular-webfont.woff2") format("woff2"), url("../fonts/lora/lora-regular-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "lora-italic";
    src: url("../fonts/lora/lora-italic-webfont.woff2") format("woff2"), url("../fonts/lora/lora-italic-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "lora-bold";
    src: url("../fonts/lora/lora-bold-webfont.woff2") format("woff2"), url("../fonts/lora/lora-bold-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "lora-bold-italic";
    src: url("../fonts/lora/lora-bolditalic-webfont.woff2") format("woff2"), url("../fonts/lora/lora-bolditalic-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


/*
 * montserrat
================================================================================ */
@font-face {
    font-family: "montserrat-black";
    src: url("../fonts/montserrat/montserrat-black-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-black-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-extrabold";
    src: url("../fonts/montserrat/montserrat-extrabold-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-extrabold-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-bold";
    src: url("../fonts/montserrat/montserrat-bold-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-bold-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-semibold";
    src: url("../fonts/montserrat/montserrat-semibold-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-semibold-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-medium";
    src: url("../fonts/montserrat/montserrat-medium-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-medium-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-regular";
    src: url("../fonts/montserrat/montserrat-regular-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-regular-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-light";
    src: url("../fonts/montserrat/montserrat-light-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-light-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-extralight";
    src: url("../fonts/montserrat/montserrat-extralight-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-extralight-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "montserrat-thin";
    src: url("../fonts/montserrat/montserrat-thin-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-thin-webfont.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


/*# sourceMappingURL=fonts.css.map */